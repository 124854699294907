'use client';

import * as React from 'react';
import { AuthSwitch } from '@mentimeter/auth';
import { useTranslations } from '@mentimeter/i18n';
import { useSearchParams } from '@mentimeter/next-navigation';
import { trackVisitor } from 'lib/tracking/trackVisitor';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Text } from '@mentimeter/ragnar-ui/text';
import type { AuthContext } from '../../constants';
import authPackage from './authPackage';

export const useSocialLoginTypeQuery = () => {
  const [socialLoginType, setSocialLoginType] = React.useState<
    string | undefined
  >(undefined);
  React.useEffect(
    () =>
      void setSocialLoginType(
        new URL(window.location.href).searchParams.get('socialLoginType') ||
          undefined,
      ),
    [],
  );
  return socialLoginType;
};

export const LogInHeader = () => {
  const t = useTranslations('auth');
  return (
    <Box mb={4} width={1} alignItems="center">
      <Text
        as="h2"
        color="text"
        fontWeight="semiBold"
        fontFamily="heading"
        lineHeight="heading"
        fontSize={[5, 6, 6, 7]}
        textAlign="center"
        mb={[2, null, 3]}
        extend={() => ({ scrollMarginTop: '130px' })}
      >
        {t('welcome_back')}
      </Text>
    </Box>
  );
};

const SignUpFooter = ({ onClick }: { onClick: (arg0: any) => void }) => {
  const t = useTranslations('auth');
  const searchParams = useSearchParams();
  const continueUrl = searchParams.get('continue');
  return (
    <>
      <Box
        flexDirection="row"
        mt={4}
        alignItems="center"
        mb={['space2', 'space4']}
        gap="space2"
      >
        <Text as="p" fontSize={[2, 3]} mb={0} fontWeight="semiBold">
          {t('already_have_account')}
        </Text>
        <Button variant="subtle" size="large" onClick={onClick}>
          {t('log_in')}
        </Button>
      </Box>
      <Button
        variant="subtle"
        size="large"
        href={`/auth/saml${continueUrl ? `?continue=${continueUrl}` : ''}`}
      >
        {t('sign_up_sso')}
      </Button>
    </>
  );
};

const LogInFooter = ({ onClick }: { onClick: (arg0: any) => void }) => {
  const t = useTranslations('auth');
  return (
    <Box flexDirection="column" alignItems="center" mt={4}>
      <Text
        as="p"
        fontSize={[2, 3]}
        fontWeight="semiBold"
        textAlign="center"
        mb={['space2', 'space4']}
      >
        {t('new_to_menti')}
      </Text>
      <Button size="large" variant="subtle" onClick={onClick}>
        {t('sign_up_now')}
      </Button>
    </Box>
  );
};

const AuthSwitchWrapper = ({
  referral,
  authContext,
  useSocialRedirect,
  signUpWrapperProps,
  showEmailAutoSuggest,
}: {
  referral: string;
  authContext: AuthContext;
  useSocialRedirect?: boolean;
  signUpWrapperProps?: Record<string, unknown>;
  showEmailAutoSuggest?: boolean;
}) => {
  const searchParams = useSearchParams();
  const continueUrlParam = searchParams.get('continue') || undefined;

  const isLogin = authContext === 'Login';
  const isSignup = authContext === 'Signup' || authContext === 'Justvoted';
  const track = isSignup ? undefined : trackVisitor;

  return (
    <Box
      width="100%"
      maxWidth="568px"
      className="authWrapper"
      alignItems="center"
    >
      <AuthSwitch
        referral={referral}
        authPackage={authPackage}
        showSignup={isSignup}
        useSocialRedirect={Boolean(useSocialRedirect)}
        track={track}
        continueUrl={continueUrlParam}
        showEmailAutoSuggest={showEmailAutoSuggest}
        render={({ SSOMessageActive, toggleAuthMode, renderAuth }) => (
          <>
            <Box borderRadius={2} width="100%">
              {isLogin && <LogInHeader />}
              {renderAuth(signUpWrapperProps)}
            </Box>
            {isSignup && !SSOMessageActive && (
              <SignUpFooter onClick={toggleAuthMode} />
            )}
            {isLogin && <LogInFooter onClick={toggleAuthMode} />}
          </>
        )}
      />
    </Box>
  );
};

export default AuthSwitchWrapper;
