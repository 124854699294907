'use client';

import { AuthPackage } from '@mentimeter/auth';

const FACEBOOK_CLIENT_ID = process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ID as string;
const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string;
const MICROSOFT_CLIENT_ID = process.env
  .NEXT_PUBLIC_MICROSOFT_CLIENT_ID as string;

const fbId = FACEBOOK_CLIENT_ID;
const googleId = GOOGLE_CLIENT_ID;
const microsoftId = MICROSOFT_CLIENT_ID;
const helloOptions = {
  facebook: fbId,
  google: googleId,
  windows: microsoftId,
};
let authPackage;
const createAuthPackage = () => {
  authPackage = new AuthPackage({
    baseUrl: process.env.NEXT_PUBLIC_EU_CORE_URL,
    helloOptions,
  });
  return authPackage;
};

export default authPackage || (createAuthPackage() as AuthPackage);
